var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4943ea76ec32060f8cc617e6f9253e7f1321c356"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { BASE_IGNORED_ERRORS_WEB } from "@glow/shared";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      "https://76d6e3c2522f4ff6b3a1cb670bde5f88@o370968.ingest.sentry.io/6174510",
    tracesSampleRate: 0.05,
    ignoreErrors: BASE_IGNORED_ERRORS_WEB,
    integrations: [
      new Sentry.Integrations.GlobalHandlers({
        onerror: true,
        // Unhandled errors usually are unrelated to us (other extensions erroring, etc.)
        onunhandledrejection: false,
      }),
    ],

    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
