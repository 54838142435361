import { startsWithHttp } from "@glow/shared";
import React from "react";

export const ExternalLink = React.forwardRef(
  (
    {
      href,
      className,
      children,
      onClick,
      ariaLabel,
      openInNewTab = true,
      ...props
    }: React.PropsWithChildren<
      {
        href: string;
        ariaLabel?: string;
        openInNewTab?: boolean;
      } & React.DetailedHTMLProps<
        React.AnchorHTMLAttributes<HTMLAnchorElement>,
        HTMLAnchorElement
      >
    >,
    ref: React.ForwardedRef<HTMLAnchorElement>,
  ) => {
    if (
      typeof href === "string" &&
      !startsWithHttp(href) &&
      !href.startsWith("chrome-extension://") &&
      !href.startsWith("moz-extension://") &&
      !href.startsWith("safari-web-extension://") &&
      !href.startsWith("mailto:")
    ) {
      href = "http://" + href;
    }

    return (
      <a
        {...props}
        ref={ref}
        href={href}
        className={className}
        aria-label={ariaLabel}
        target={openInNewTab ? "_blank" : "_self"}
        rel="nofollow noopener"
        onClick={onClick}
      >
        {children}
      </a>
    );
  },
);
